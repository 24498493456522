import React from "react"
import Title from "./Title"
import { FaAngleDoubleRight } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Image from "gatsby-image"


const query = graphql`
  {
    allStrapiCertifications(sort: {fields: id, order: DESC}) {
      nodes {
        certificationName
        strapiId
        description
        certificationImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG

              
            }
          }
        }
      }
    }
  }`

const Certifications = ({certificationName,certificationImage,description,strapiId}) => {

  const data = useStaticQuery(query)
  //console.log(data)
  const {
    allStrapiCertifications: {
      nodes:Certifications
    },
  } = data

   
 
 return (

    <div className="cert-stack">
    {Certifications.map((item, index)=>{
      return (<div className="cert-img"
                 key={item.strapiId}
                 > 
                 
               <Image className="" fluid={item.certificationImage.childImageSharp.fluid}/>
             </div>
      )
    })}
  </div>
 )
   
  
}

export default Certifications
