import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import Blogs from "../components/Blogs"
import Footer from "../components/Footer"

export default ({data}) => {
  //console.log(data)
  const {
    allStrapiProjects:{nodes:projects},
    allStrapiBlogs:{nodes:blogs},
  } = data

  return (<Layout>
    <Hero/>
    <Services/>
    <Jobs/>
    <Projects projects={projects} title="featured projects" showLink/>
    <Blogs blogs={blogs} title="Latest Articles" showLink/>
     
  </Layout>)

}
export const query = graphql`
  {
    allStrapiProjects(filter: {featured: {eq: true}}) {
      nodes {
        github
        id
        description
        title
        url
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        stack {
          id
          title
          stackimage {
            childImageSharp {
              fixed(height: 20) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
           }
          }
        }
      }
    }
    allStrapiBlogs(sort: {fields: id, order: DESC}, limit: 3) {
      nodes {
        slug
        content
        desc
        date(formatString: "MMMM Do, YYYY")
        id
        title
        category
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }

    
  }`

