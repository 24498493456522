import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"
import Certification from "../components/Certification"

import Certifications from "../constants/certifications"
import { FaAws, FaCode, FaDocker, FaDharmachakra,FaAndroid,FaApple, FaMotorcycle, FaTasks } from "react-icons/fa"
import TypeIt from "typeit-react"
// ...GatsbyImageSharpFluid
const query = graphql`
{
  file(relativePath: {eq: "hero-img.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
}
`
const Hero = () => {
  const {
    file:{
      childImageSharp:{
        fluid
      }
    }
  }  = useStaticQuery(query)
  
    return <header className="hero">
      <div className="section-center hero-center ">
      <Image fluid={fluid} className="hero-img"/>
        <article className="hero-info">
          <div>
            <div className="underline"></div>
           <TypeIt element={"h1"}style={{display: "inline",textTransform: "none"}}>I'm Nishant.</TypeIt>
                      
              <div className="wrapper" style={{marginBottom: "2rem"}}>
                <div className="spinny-wrapper">
                <span className="spinny-words">
                  <h4> <FaCode className="social-icon"/> Full Stack Web Developer.</h4>
                  <h4> <FaApple className="social-icon"/> <FaAndroid className="social-icon" /> Full Stack Mobile Developer</h4>
                  <h4><FaTasks className="social-icon"/>  Seasoned Project Manager</h4>
                  <h4><FaAws className="social-icon"/> Professional Solutions Architect </h4>
                  <h4><FaAws className="social-icon"/> Hands On DevOps Engineer </h4>  
                  <h4><FaDocker className="social-icon"/> <FaDharmachakra className="social-icon"/>  Certified Kubernetes Expert</h4>
                  <h4><FaMotorcycle className="social-icon"/> An Avid Biker </h4>
                </span>
                </div>
               </div>
          
            <Link to="/contact" className="btn">
              let's connect
            </Link>
            {/*<SocialLinks/>*/}
             
            <Certification/>
          </div>
        </article>
        
        
      </div>
    </header>
}

export default Hero
